import React from "react";
import { Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
const sign = require("jwt-encode");
const secret = "Create2022!";

export const getAccessToken = () => {
	return localStorage.getItem("killbillToken");
};

export const clearAccessToken = () => {
	localStorage.removeItem("killbillToken");
	localStorage.removeItem("userDetail");
	localStorage.clear();
};

export const setAccessToken = (token) => {
	localStorage.setItem("killbillToken", token);
};

export const isLoggedIn = () => {
	const accessToken = getAccessToken();
	return !!accessToken;
};

export const RequireAuth = ({ children }) => {
	return isLoggedIn() ? children : <Navigate to="/" replace />;
};

export const LoginedIn = ({ children }) => {
	return !isLoggedIn() ? children : <Navigate to="/dashboard" replace />;
};

export const getUserDetail = () => {
	const jwt = localStorage.getItem("userDetail");
	if (jwt && jwt !== null && jwt !== "") {
		return jwt_decode(jwt);
	}
	return false;
};

export const setUserDetail = (userDetail) => {
	const jwt = sign(userDetail, secret);
	localStorage.setItem("userDetail", jwt);
};

export const setCartToken = (cartToken) => {
	localStorage.setItem("cartToken", cartToken);
};

export const getCartToken = () => {
	const jwt = localStorage.getItem("cartToken");
	if (jwt && jwt !== null && jwt !== "") {
		const cartToken = jwt_decode(jwt);
		return cartToken.reqData;
	}
	return false;
};
