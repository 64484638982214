/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Card, Form, Table } from "antd";
import jwt_decode from "jwt-decode";
import { setCartToken } from "../../utils";

import * as UserAction from "../../Action/KillBill";
import Loader from "../../CommonComponent/Loader";
import "./style.scss";
import StripeForm from "./StripeForm";

const Checkout = () => {
    const navigate = useNavigate();
    const { jwtToken } = useParams();
    const [cartDetail, setCartDetail] = useState({});

    const killbillReducer = useSelector((state) => ({
        token: state.killbillReducer.token,
        loading: state.killbillReducer.loading,
    }));

    useEffect(() => {
        if (jwtToken) {
            setCartToken(jwtToken);
            setCartDetail(jwt_decode(jwtToken).reqData);
            console.log(jwt_decode(jwtToken));
        }
    }, [jwtToken]);

    const columns = [
        {
            title: "Product Name",
            dataIndex: "ProductItemName",
            key: "ProductItemName",
        },
        {
            title: "Product Description",
            dataIndex: "ProductDesc",
            key: "ProductDesc",
        },
        {
            title: "Qty",
            dataIndex: "ProductQty",
            key: "ProductQty",
        },
        {
            title: "Price",
            dataIndex: "ProductItemPrice",
            key: "ProductItemPrice",
        },
    ];

    return (
        <React.Fragment>
            <div className="catagory">
                {Object.keys(cartDetail).length > 0 ? (
                    <Card className="w-3/12 block login_card">
                        <Form>
                            <b> Hello {cartDetail.Name}! </b>
                            <p>
                                Here is the summary of your selection, please
                                check before proceeding to checkout:
                            </p>
                            <p>
                                <span>Payment Type: </span>{" "}
                                <b>{cartDetail.PaymentProductType}</b>
                            </p>
                            <p>
                                <span>Subtotal: </span>{" "}
                                <b>
                                    {cartDetail.BillingAmount +
                                        cartDetail.DiscoutAmount}
                                </b>
                            </p>
                            <p>
                                <span>Discount: </span>{" "}
                                <b>{cartDetail.DiscoutAmount}</b>
                            </p>
                            <p>
                                <span>Total: </span>{" "}
                                <b>{cartDetail.BillingAmount}</b>
                            </p>
                            <Table
                                dataSource={cartDetail.Products}
                                columns={columns}
                                pagination={false}
                                style={{ paddingBottom: "15px" }}
                            />
                        </Form>
                        <StripeForm />
                    </Card>
                ) : (
                    <Card
                        className="w-3/12 block login_card"
                        style={{ textAlign: "center" }}
                    >
                        <p>
                            <b>
                                Cart Detail Expire, please go back and update
                                detail again.
                            </b>
                        </p>
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => navigate(-1)}
                        >
                            Go Back
                        </Button>
                    </Card>
                )}

                <div className="footer">
                    <span>SICOP © 2022</span>
                </div>
            </div>
            {killbillReducer.loading && <Loader />}
        </React.Fragment>
    );
};

const mapDispatchToProps = (dispatch) => ({
    actions: {
        userAction: bindActionCreators(UserAction, dispatch),
    },
});

export default connect(null, mapDispatchToProps)(Checkout);
