import {
	INPROGRESS,
	IS_AUTHENTICATED,
	ERROR,
	LOGOUT,
	SET_CHARGES,
	COMPLETE,
	SUCCESS,
	PAYMENT_SUCESS,
	USER_PAYMENT_DETAIL,
} from "../utils/Constant";
import initialState from "./initialState";

export default function killbillReducer(
	state = initialState.killbillReducer,
	action
) {
	switch (action.type) {
		case INPROGRESS:
			return {
				...state,
				error_msg: "",
				success_msg: null,
				loading: true,
				transaction_data: null,
			};

		case COMPLETE:
			return {
				...state,
				loading: false,
				error_msg: null,
				success_msg: null,
			};

		case ERROR:
			return {
				...state,
				error_msg: action.data.error_msg + " E:" + Math.random(),
				success_msg: null,
				loading: false,
			};

		case SUCCESS:
			console.log("transaction data", action.data);
			return {
				...state,
				error_msg: null,
				success_msg: action.data.message,
				transaction_data: action.data,
				loading: false,
			};

		case LOGOUT:
			return {
				...state,
				loading: false,
				error_msg: null,
				success_msg: null,
				token: null,
				userDetail: null,
				users: [],
			};

		case IS_AUTHENTICATED:
			return {
				...state,
				error_msg: null,
				success_msg: null,
				loading: false,
				token: action.data.token,
				userDetail: action.data,
			};

		case SET_CHARGES:
			return {
				...state,
				error_msg: null,
				stripeCharge: action.data,
			};

		case PAYMENT_SUCESS:
			return {
				...state,
				error_msg: null,
				success_msg: action.data,
				transaction_data: null,
				loading: false,
			};

		case USER_PAYMENT_DETAIL:
			return {
				...state,
				error_msg: null,
				success_msg: null,
				userPayment: action.data,
				loading: false,
			};

		default:
			return state;
	}
}
