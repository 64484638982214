import { getAccessToken, getUserDetail } from "../utils";
const accessToken = getAccessToken();

let token = null;
let userDetail = null;
if (accessToken !== "undefined" && accessToken !== null) {
  token = accessToken;
  userDetail = getUserDetail();
}

const listProducts = [
  {
    id: 1,
    name: "daily_Subscription",
    displayName: "$17 daily recurrings",
    value: 17,
    tokenAmount: 1,
  },
  {
    id: 2,
    name: "recurrings",
    displayName: "$20 weekly recurrings",
    value: 20,
    tokenAmount: 1,
  },
];

const initialState = {
  killbillReducer: {
    loading: false,
    error_msg: null,
    success_msg: null,
    token: token,
    userDetail: userDetail,
    users: [],
    userPayment: [],
    listProducts: listProducts,
    stripeCharge: listProducts[0],
    transaction_data : null,
  },
};

export default initialState;
