export const ENV = "development";

const ENVIRONMENT_LOCAL = {
    Base_API_URL: "http://localhost:3005",
};

const ENVIRONMENT_DEVELOPMENT = {
    Base_API_URL: "https://killbillnode.theadventus.com",
};

const ENVIRONMENT_PRODUCTION = {
    Base_API_URL: "https://killbillnode.theadventus.com",
};

export const THIRD_PARTY_KILLBILL_LOCAL = "http://localhost:3001/success/";

export const THIRD_PARTY_KILLBILL_LIVE =
    "https://3rdparty.killbill.theadventus.com/success/";

let ENVIRONMENT_VARIABLES;

if (ENV === "local") {
    ENVIRONMENT_VARIABLES = ENVIRONMENT_LOCAL;
} else if (ENV === "development") {
    ENVIRONMENT_VARIABLES = ENVIRONMENT_DEVELOPMENT;
} else {
    ENVIRONMENT_VARIABLES = ENVIRONMENT_PRODUCTION;
}

export default ENVIRONMENT_VARIABLES;
