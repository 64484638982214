/* eslint-disable no-unreachable */
import axios from "axios";
import { isEmpty } from "lodash";
import {
    IS_AUTHENTICATED,
    ERROR,
    INPROGRESS,
    LOGOUT,
    PAYMENT_SUCESS,
    USER_PAYMENT_DETAIL,
} from "../utils/Constant";
import ENVIRONMENT_VARIABLES from "../environment.config";
import {
    setAccessToken,
    clearAccessToken,
    setUserDetail,
    getAccessToken,
} from "../utils";

export const login = (loginDetails) => {
    try {
        return (dispatch) => {
            dispatch({ type: INPROGRESS });
            console.log(loginDetails);
            if (isEmpty(loginDetails.email) || isEmpty(loginDetails.password))
                return dispatch({
                    type: ERROR,
                    data: { error_msg: "email and Password are required" },
                });

            var config = {
                method: "post",
                url: ENVIRONMENT_VARIABLES.Base_API_URL + "/users/login",
                headers: {
                    "Content-Type": "application/json",
                },
                data: loginDetails,
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    if (response.status === 200) {
                        setAccessToken(response.data.token);
                        setUserDetail(response.data);
                        dispatch({
                            type: IS_AUTHENTICATED,
                            data: response.data,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    if (error && error.response) {
                        dispatch({
                            type: ERROR,
                            data: { error_msg: error.response.data.message },
                        });
                    } else {
                        dispatch({
                            type: ERROR,
                            data: { error_msg: error.message.toString() },
                        });
                    }
                });
        };
    } catch (error) {
        alert(error.message.toString());
    }
};

export const logout = () => {
    try {
        return (dispatch) => {
            clearAccessToken();
            dispatch({
                type: LOGOUT,
            });
        };
    } catch (error) {
        alert(error.message.toString());
    }
};

export const payment = async (paymentDetail) => {
    try {
        var config = {
            method: "post",
            url: ENVIRONMENT_VARIABLES.Base_API_URL + "/killbill/search",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getAccessToken(),
            },
            data: paymentDetail,
        };

        return await axios(config);
    } catch (error) {
        alert(error.message.toString());
    }
};

export const savePaymentDetail = async (paymentDetail) => {
    try {
        return (dispatch) => {
            console.log(paymentDetail);
            dispatch({ type: INPROGRESS });
            const apiData = {
                userId: parseInt(paymentDetail.userId),
                amount: parseInt(paymentDetail.transactionData.amount),
                currency: paymentDetail.transactionData.currency.toString(),
            };
            var config = {
                method: "post",
                url: ENVIRONMENT_VARIABLES.Base_API_URL + "/payment/save",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + getAccessToken(),
                },
                data: apiData,
            };

            axios(config)
                .then(function (response) {
                    dispatch({
                        type: PAYMENT_SUCESS,
                        data: response.data,
                    });
                })
                .catch(function (error) {
                    if (error.message) {
                        dispatch({
                            type: ERROR,
                            data: { error_msg: error.message.toString() },
                        });
                    } else {
                        dispatch({
                            type: ERROR,
                            data: { error_msg: error.reponse.data.toString() },
                        });
                    }
                });
        };
    } catch (error) {
        alert(error.message.toString());
    }
};

export const getUserPayment = (userId) => {
    try {
        return (dispatch) => {
            dispatch({ type: INPROGRESS });
            var config = {
                method: "get",
                url: ENVIRONMENT_VARIABLES.Base_API_URL + "/payment/" + userId,
                headers: {
                    Authorization: "Bearer " + getAccessToken(),
                },
            };

            axios(config)
                .then(function (response) {
                    dispatch({
                        type: USER_PAYMENT_DETAIL,
                        data: response.data,
                    });
                })
                .catch(function (error) {
                    if (error.message) {
                        dispatch({
                            type: ERROR,
                            data: { error_msg: error.message.toString() },
                        });
                    } else {
                        dispatch({
                            type: ERROR,
                            data: { error_msg: error.reponse.data.toString() },
                        });
                    }
                });
        };
    } catch (error) {
        alert(error.message.toString());
    }
};

export const getUserDetail = (userData) => {
    try {
        return (dispatch) => {
            console.log(userData);
            // dispatch({ type: INPROGRESS });
        };
    } catch (error) {
        alert(error.message.toString());
    }
};

export const singlePayment = async (paymentDetail) => {
    try {
        var config = {
            method: "post",
            url: ENVIRONMENT_VARIABLES.Base_API_URL + "/killbill/singlePayment",
            headers: {
                "Content-Type": "application/json",
            },
            data: paymentDetail,
        };
        console.log(config);
        const response = await axios(config);
        return response;
    } catch (error) {
        alert(error.message.toString());
    }
};

export const recurringPayment = async (paymentDetail) => {
    try {
        var config = {
            method: "post",
            url:
                ENVIRONMENT_VARIABLES.Base_API_URL +
                "/killbill/recurringPayment",
            headers: {
                "Content-Type": "application/json",
            },
            data: paymentDetail,
        };
        console.log(config);
        const response = await axios(config);
        return response;
    } catch (error) {
        alert(error.message.toString());
    }
};
