import React from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loadStripe } from "@stripe/stripe-js";
import {
    Elements,
    useStripe,
    useElements,
    CardElement,
} from "@stripe/react-stripe-js";
import { toast, ToastContainer } from "react-toastify";
import * as UserAction from "../../Action/KillBill";
import { INPROGRESS, COMPLETE, SUCCESS, ERROR } from "../../utils/Constant";
import { getCartToken } from "../../utils";
import {
    THIRD_PARTY_KILLBILL_LOCAL,
    THIRD_PARTY_KILLBILL_LIVE,
    ENV,
} from "../../environment.config";

import "./StripeForm.scss";
import { Modal } from "antd";
const sign = require("jwt-encode");

const notificationSetting = {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
};

const CARD_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: "Arial, sans-serif",
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#32325d",
            },
        },
        invalid: {
            fontFamily: "Arial, sans-serif",
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(event);
        dispatch({ type: INPROGRESS });
        if (elements == null) {
            return;
        }

        const { token, error } = await stripe.createToken(
            elements.getElement(CardElement)
        );

        if (token) {
            const cartToken = getCartToken();
            console.log(cartToken);

            dispatch({ type: COMPLETE });

            const paymentData = {
                name: cartToken.Name,
                email: cartToken.Email,
                stripeCharge: cartToken.BillingAmount,
                accountId: cartToken.acccoutId,
                paymentMethodId: cartToken.paymentMethodId,
                stripeToken: token.id,
                stripeProductName: cartToken.PaymentProductRecurringId
                    ? cartToken.PaymentProductRecurringId
                    : "",
            };

            dispatch({ type: INPROGRESS });

            debugger;
            if (
                cartToken.PaymentProductType &&
                cartToken.PaymentProductType === "oneTime"
            ) {
                debugger;
                UserAction.singlePayment(paymentData)
                    .then(function (response) {
                        console.log(JSON.stringify(response.data));
                        const resData = response.data;
                        if (response.status === 200) {
                            // toast.success(
                            //     response.data.message,
                            //     notificationSetting
                            // );
                            dispatch({
                                type: SUCCESS,
                                data: response.data,
                            });
                            document.getElementsByClassName(
                                "stripe-checkout-element"
                            )[0].style.display = "none";
                            Modal.success({
                                content: <p>Payment done successfully</p>,
                                onOk() {
                                    window.location.href =
                                        ENV === "local"
                                            ? THIRD_PARTY_KILLBILL_LOCAL +
                                              sign(
                                                  { ...cartToken, resData },
                                                  "feeeb10b-6f5b-4888-8199-fe490e749200"
                                              )
                                            : THIRD_PARTY_KILLBILL_LIVE +
                                              sign(
                                                  { ...cartToken, resData },
                                                  "feeeb10b-6f5b-4888-8199-fe490e749200"
                                              );
                                },
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        debugger;
                        if (error && error.response) {
                            dispatch({
                                type: ERROR,
                                data: { error_msg: error.response.data },
                            });
                        } else {
                            dispatch({
                                type: ERROR,
                                data: { error_msg: error.toString() },
                            });
                        }
                    });
            } else {
                debugger;
                UserAction.recurringPayment(paymentData)
                    .then(function (response) {
                        console.log(JSON.stringify(response.data));
                        const resData = response.data;
                        if (response.status === 200) {
                            dispatch({
                                type: SUCCESS,
                                data: response.data,
                            });
                            document.getElementsByClassName(
                                "stripe-checkout-element"
                            )[0].style.display = "none";

                            Modal.success({
                                content: <p>Payment done successfully</p>,
                                onOk() {
                                    window.location.href =
                                        ENV === "local"
                                            ? THIRD_PARTY_KILLBILL_LOCAL +
                                              sign(
                                                  { ...cartToken, resData },
                                                  "feeeb10b-6f5b-4888-8199-fe490e749200"
                                              )
                                            : THIRD_PARTY_KILLBILL_LIVE +
                                              sign(
                                                  { ...cartToken, resData },
                                                  "feeeb10b-6f5b-4888-8199-fe490e749200"
                                              );
                                },
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        debugger;
                        if (error && error.response) {
                            dispatch({
                                type: ERROR,
                                data: { error_msg: error.response.data },
                            });
                        } else {
                            dispatch({
                                type: ERROR,
                                data: { error_msg: error.toString() },
                            });
                        }
                    });
            }
        }

        if (error) {
            toast.error(
                `Error in Stripe token generation, please try again aftersome time.`,
                notificationSetting
            );
            dispatch({
                type: COMPLETE,
            });
        }
    };
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="FormRow">
                    <CardElement id="card-element" options={CARD_OPTIONS} />
                </div>
                <button
                    type="submit"
                    disabled={!stripe || !elements}
                    className="stripe-button-el"
                >
                    <span>Pay with Card</span>
                </button>
            </form>
        </>
    );
};

const StripeForm = () => {
    const stripePromise = loadStripe(
        "pk_test_51LND1mInhUzmZtb4aIy7Yv3f6n62GQHyRgkHVJioE33BxLHxTEiGTwMnb2NtxgRosDmQlaF8Tdw0ixENKlDOgLC200wSwF2s3R"
    );

    return (
        <>
            <ToastContainer />
            <div
                style={{ textAlign: "center" }}
                className="stripe-checkout-element"
            >
                <Elements stripe={stripePromise}>
                    <CheckoutForm />
                </Elements>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    actions: {
        userAction: bindActionCreators(UserAction, dispatch),
    },
});

export default connect(null, mapDispatchToProps)(StripeForm);
