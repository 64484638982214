import { Provider } from "react-redux";
import logger from "redux-logger";
import promise from "redux-promise";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { applyMiddleware, createStore } from "redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import rootReducer from "./Reducer";
import initialState from "./Reducer/initialState";
import "./App.css";
import Checkout from "./Components/Checkout";
import PageNotFound from "./Components/PageNotFound";

const composeEnhancers = composeWithDevTools({});

// const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk, promise)));
const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk, promise, logger))
);

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    {/* <Route path="/" element={<Category />} /> */}
                    <Route path="/checkout/:jwtToken" element={<Checkout />} />
                    {/* <Route
						path="Login"
						element={
							<LoginedIn>
								<Login />
							</LoginedIn>
						}
					/>
					<Route
						exact
						path="/dashboard"
						element={
							<RequireAuth>
								<DashBoard />
							</RequireAuth>
						}
					>
						<Route index element={<ProductSummary />} />
						<Route path="purchase" element={<StripeForm />} />
						<Route path="subcription" element={<Subcription />} />
					</Route> */}
                    <Route exact path="*" element={<PageNotFound />} />
                </Routes>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
